<template>
  <v-dialog v-model="active" width="800" persistent>
    <v-card>
      <v-card-title primary-title>
        <img :src="require('@/assets/shield.svg')" style="margin-right:1em;" contain height="50" alt="Covenant Shield Logo" />
        Scots Portal Offline
      </v-card-title>
      <v-card-text>
        <p>You are currently unable to connect to the Scots Portal. This could be due to planned maintenance or the system needing to restart, or a problem with the server, or a problem with your device or network. Here are some troubleshooting tips:</p>
        <ul>
          <li>Wait a minute. If it just went offline while you were using it, then it's possible that it is a temporary interrupt in the connection. Try waiting a minute to see if it comes back.</li>
          <li>Refresh the page. If it's planned maintenance then a maintenance message should appear. If not, then it may be a connection problem on your end.</li>
          <li>The Scots Portal uses a websocket to connect to the server. Some public networks block access to websockets, so if you are on a public network then you may need to try a different network.</li>
          <li>Some Internet Security software blocks access to the websocket. In this case you may need to either turn off your internet security software while using the Portal, or find a way to add an exception for the Portal. If you are on campus, bring your device to the Tech Services office and we can try to help look at it with you.</li>
        </ul>
        <p style="margin-top:1em">If none of the above helps and the system remains offline, please contact Tech Support at <a href="mailto:techsupport@covenant.edu?subject=Scots+Portal+Offline" target="_blank">techsupport@covenant.edu</a> to report this problem.</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const active = computed(() => root.$store.state.main.offline)

    return {
      active
    }
  }
}
</script>
